import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAccount } from "../../../store/account";
import { XLg } from "react-bootstrap-icons";

export default function PrimaryMenu(props) {
	const accountStore = useAccount();
	const [collapsed, setCollapsed] = useState(false)
	const history = useHistory()

	const toggleNavbar = () => setCollapsed(!collapsed);

	const classOne = !collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
	const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

	useEffect(() => {
		history.listen((location) => setCollapsed(false))
	}, [history])

	return (
		<>
			<div className="primary-menu container">
				<div className={`side-collapse-container box-items ${collapsed ? 'd-none' : 'd-flex '} rounded-pill`} style={{ fontWeight: '500' }}>
					<div style={{ gap: '1rem', padding: '1rem', width: '100%', justifyContent: 'center' }} className="d-md-flex d-none align-items-center">
						<Link to="/" className="link">
							<div className="item">Inicio</div>
						</Link>
						<Link to="/productos" className="link">
							<div className="item">Productos</div>
						</Link>
						<Link to="/nosotros" className="link">
							<div className="item">Nosotros</div>
						</Link>
						<Link to="/contacto" className="link">
							<div className="item">Contacto</div>
						</Link>
					</div>
					<button
						onClick={toggleNavbar}
						className={`${classTwo} d-md-none col-2`}
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						href="#navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon">
							<i className="fas fa-bars" style={{ color: 'var(--text-color-with-primary)', fontSize: '26px' }}></i>
						</span>
					</button>
				</div>
				{
					collapsed &&
					<header role="banner" className={`${classOne} navbar navbar-fixed-top navbar-inverse collapse side-collapse-container ${collapsed ? 'out' : ''}  `} id="collapseExample">
						<div className="container">
							<div className="navbar-inverse side-collapse">
								<div className='d-flex flex-row'>
									<div className='col-10'>
										<nav role="navigation" className="navbar-collapse">
											<ul className="navbar-nav flex-column d-md-none mt-5" style={{ gap: '15px', fontSize: '12px', textAlign: 'left' }}>
												<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
													<Link to='/' className={`nav-link primary-color`}>
														Inicio
													</Link>
												</li>
												<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
													<Link to="/productos" className="primary-color nav-link">
														<div className="item">Productos</div>
													</Link>
												</li>
												<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
													<Link to="/nosotros" className="primary-color nav-link">
														<div className="item">Nosotros</div>
													</Link>
												</li>
												<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
													<Link to="/contacto" className="primary-color nav-link">
														<div className="item">Contacto</div>
													</Link>
												</li>
											</ul>
										</nav>
									</div>
									<div className='col-1 p-2'>
										<XLg onClick={() => setCollapsed(false)} className="primary-color" />
									</div>
								</div>
							</div>
						</div>
					</header>
				}
			</div>
		</>
	);
};
