import React, { useEffect, useState } from 'react';
import './styles.scss';
import { ContactUs } from '../../components/home/ContactUs';
import { Constants } from '../../Constants';
import Axios from 'axios';
import { Carousel } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

export default function Products(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [totals, setTotals] = useState(1);
    const [offset, setOffset] = useState(12);

    useEffect(() => {
        setLoading(true);
        Axios.get(`${Constants.BASE_URL}/items/get-items?page=${page}&offset=${offset}`)
            .then((response) => {
                setItems(response.data.data.items);
                setTotals(response.data.data.totals);
                if (page > 1) {
                    window.scrollTo(0, document.getElementById('items').offsetTop - 40);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [page]);

    return (
        <>
            <div id="Products" className='container section'>
                <div className='row'>
                    <div className="col-12 mb-5">
                        <div className="text-center d-flex flex-row mt-5">
                            <span className='rounded-pill mr-4 border-title-third text-primary-background' ></span>
                            <h1 className='font-weight-bold'>
                                <span className='text-primary-background'>PRODUCTOS</span>
                            </h1>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ marginBottom: '0.5rem' }}>
                    {/* aca hago a la derecha un select para ordenar por mayor precio */}
                    <div className='col-12'>
                        <div className='d-flex justify-content-end'>
                            <select style={{ maxWidth: 'max-content' }} className='form-control' onChange={(e) => console.log(e.target.value)}>
                                <option value=''>Ordenar por</option>
                                <option value='1'>Mayor precio</option>
                                <option value='2'>Menor precio</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3 filter-box'>
                        <h5>Filtros</h5>
                        <div className='filter'>
                            <h6>Categorias</h6>
                            <ul>
                                <li>
                                    <input type='checkbox' />
                                    <label>Antiguedades</label>
                                </li>
                                <li>
                                    <input type='checkbox' />
                                    <label>Arte</label>
                                </li>
                                <li>
                                    <input type='checkbox' />
                                    <label>Decoracion</label>
                                </li>
                                <li>
                                    <input type='checkbox' />
                                    <label>Iluminacion</label>
                                </li>
                                <li>
                                    <input type='checkbox' />
                                    <label>Muebles</label>
                                </li>
                                <li>
                                    <input type='checkbox' />
                                    <label>Otros</label>
                                </li>
                            </ul>
                        </div>
                        <div className='filter'>
                            <h6>Moneda</h6>
                            <ul>
                                <li>
                                    <input type='checkbox' />
                                    <label>UYU</label>
                                </li>
                                <li>
                                    <input type='checkbox' />
                                    <label>USD</label>
                                </li>
                            </ul>
                        </div>
                        <div className='filter'>
                            <h6>Precio</h6>
                            <ul>
                                <li>
                                    <input type='checkbox' />
                                    <label>Menor a $1000</label>
                                </li>
                                <li>
                                    <input type='checkbox' />
                                    <label>Entre $1000 y $5000</label>
                                </li>
                                <li>
                                    <input type='checkbox' />
                                    <label>Mayor a $5000</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-9'>
                        <div className='row'>
                            {items.map((item) => (
                                <div key={item.id} className='col-md-4 col-sm-4 col-xs-12'>
                                    <div className='item'>
                                        <Carousel variant="dark" interval={null}>
                                            {item.pictures.map(image =>
                                                <Carousel.Item>
                                                    <img
                                                        onClick={() => props.history.push(`/items/${item.id}`)}
                                                        style={{ cursor: 'pointer' }}
                                                        className="d-block w-100"
                                                        src={`${image.url}`}
                                                        alt="sin imagen"
                                                    />
                                                </Carousel.Item>
                                            )}
                                        </Carousel>
                                        <div className='item-details'>
                                            <Link to={`/items/${item.id}`}>
                                                <p>{item.title}</p>
                                                <h4>{item.currencyId === 'UYU' ? '$' : 'US$'} {item.price}</h4>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className='container-pagination'>
                                <Pagination
                                    activePage={page}
                                    itemsCountPerPage={offset}
                                    totalItemsCount={totals}
                                    pageRangeDisplayed={
                                        window.innerWidth < 768 ? 3 : 5
                                    } // si es mobile poner 3
                                    onChange={(value) => setPage(value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
        </>
    );
}